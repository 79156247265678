<template>
  <div ref="signaturePreview" style="padding: 0; margin: 0">
    <table
      cellpadding="0"
      cellspacing="0"
      border="0"
      globalstyles="[object Object]"
      style="
        vertical-align: -webkit-baseline-middle;
        font-size: medium;
        font-family: Calibri, sans-serif;
      "
      ref="signatureTable"
    >
      <tbody>
        <tr>
          <td nowrap>
            <table
              cellpadding="0"
              cellspacing="0"
              border="0"
              globalstyles="[object Object]"
              style="
                vertical-align: -webkit-baseline-middle;
                font-size: medium;
                font-family: Calibri, sans-serif;
              "
            >
              <tbody>
                <tr>
                  <td nowrap style="vertical-align: top">
                    <table
                      cellpadding="0"
                      cellspacing="0"
                      border="0"
                      globalstyles="[object Object]"
                      style="
                        vertical-align: -webkit-baseline-middle;
                        font-size: medium;
                        font-family: Calibri, sans-serif;
                      "
                    >
                      <tbody>
                        <tr>
                          <td nowrap style="text-align: center">
                            <a
                              :href="'https://' + signature.company_website"
                              target="_blank"
                            >
                              <img
                                src="@/assets/company-logo.png"
                                alt="Logo"
                                role="presentation"
                                width="118"
                                style="display: block; max-width: 118px"
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td
                            nowrap
                            style="text-align: center; padding-top: 10px"
                          >
                            <table
                              cellpadding="0"
                              cellspacing="0"
                              border="0"
                              globalstyles="[object Object]"
                              style="
                                display: inline-block;
                                vertical-align: -webkit-baseline-middle;
                                font-size: medium;
                                font-family: Calibri, sans-serif;
                              "
                            >
                              <tbody>
                                <tr style="text-align: center">
                                  <td nowrap>
                                    <img
                                      src="@/assets/tagline.png"
                                      alt="tagline"
                                      width="112"
                                      style="width: 112px; margin-top: 8px"
                                    />
                                  </td>
                                  <td nowrap width="5">
                                    <div></div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td nowrap width="20">
                    <div></div>
                  </td>
                  <td nowrap style="padding: 0px; vertical-align: top">
                    <h2
                      color="#003D8C"
                      style="
                        margin: 0px;
                        font-size: 16px;
                        color: #003d8c;
                        font-weight: 600;
                      "
                    >
                      <span>{{ signature.name || "Your Name" }}</span>
                    </h2>
                    <p
                      color="#003D8C"
                      font-size="medium"
                      style="
                        margin: 0px;
                        color: #003d8c;
                        font-size: 14px;
                        line-height: 22px;
                      "
                    >
                      <span>{{ signature.title || "Your Title" }}</span>
                    </p>
                    <p
                      color="#003D8C"
                      font-size="medium"
                      style="
                        margin: 0px;
                        font-weight: 500;
                        color: #003d8c;
                        font-size: 14px;
                        line-height: 22px;
                      "
                    >
                      <span>{{
                        signature.company || "Your Company Name"
                      }}</span>
                    </p>

                    <table>
                      <tbody>
                        <tr
                          height="13"
                          style="vertical-align: middle; height: 13px"
                        >
                          <td
                            nowrap
                            width="130"
                            height="13"
                            style="height: 13px; vertical-align: top"
                          >
                            <div
                              style="
                                vertical-align: middle;
                                height: 10px;
                                font-size: 10px;
                              "
                            >
                              <p style="margin: 0">
                                <span style="font-size: 6px">&nbsp;</span>
                              </p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table
                      cellpadding="0"
                      cellspacing="0"
                      border="0"
                      globalstyles="[object Object]"
                      style="
                        vertical-align: -webkit-baseline-middle;
                        font-size: medium;
                        font-family: Calibri, sans-serif;
                      "
                    >
                      <tbody>
                        <tr
                          height="25"
                          style="vertical-align: middle; height: 25px"
                        >
                          <td
                            nowrap
                            width="26"
                            height="25"
                            style="vertical-align: middle; height: 25px"
                          >
                            <table
                              cellpadding="0"
                              cellspacing="0"
                              border="0"
                              globalstyles="[object Object]"
                              style="
                                vertical-align: -webkit-baseline-middle;
                                font-size: medium;
                                font-family: Calibri, sans-serif;
                              "
                            >
                              <tbody>
                                <tr>
                                  <td nowrap style="vertical-align: bottom">
                                    <span
                                      color="#003D8C"
                                      width="14"
                                      style="display: inline-block"
                                      ><img
                                        src="@/assets/phone-icon.png"
                                        color="#003D8C"
                                        alt="mobilePhone"
                                        width="14"
                                        height="14"
                                        style="
                                          display: block;
                                          width: 14px;
                                          height: 14px;
                                        "
                                    /></span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td
                            nowrap
                            height="25"
                            style="padding: 0px; height: 25px; color: #757575"
                          >
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    <p style="margin: 0">
                                      <span
                                        style="
                                          font-size: 10px;
                                          text-decoration: none;
                                        "
                                      >
                                        <a
                                          :href="'tel:' + signature.phone"
                                          color="#757575"
                                          style="
                                            text-decoration: none;
                                            border-bottom: 0px;
                                            color: #757575;
                                            font-size: 10px;
                                          "
                                          ><span
                                            style="text-decoration: none"
                                            >{{
                                              signature.phone || "1231231231"
                                            }}</span
                                          ></a
                                        ></span
                                      >
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr
                          height="25"
                          style="vertical-align: middle; height: 25px"
                        >
                          <td
                            nowrap
                            width="26"
                            height="25"
                            style="vertical-align: middle; height: 25px"
                          >
                            <table
                              cellpadding="0"
                              cellspacing="0"
                              border="0"
                              globalstyles="[object Object]"
                              style="
                                vertical-align: -webkit-baseline-middle;
                                font-size: medium;
                                font-family: Calibri, sans-serif;
                              "
                            >
                              <tbody>
                                <tr>
                                  <td nowrap style="vertical-align: bottom">
                                    <span
                                      color="#003D8C"
                                      width="14"
                                      style="display: inline-block"
                                      ><img
                                        src="@/assets/email-icon.png"
                                        color="#003D8C"
                                        alt="emailAddress"
                                        width="14"
                                        height="12"
                                        style="
                                          display: block;
                                          width: 14px;
                                          height: 12px;
                                        "
                                    /></span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td
                            nowrap
                            height="25"
                            style="padding: 0px; height: 25px"
                          >
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    <p style="margin: 0">
                                      <span
                                        style="
                                          font-size: 10px;
                                          text-decoration: none;
                                        "
                                      >
                                        <a
                                          :href="'mailto:' + signature.email"
                                          color="#757575"
                                          style="
                                            text-decoration: none;
                                            border-bottom: 0px;
                                            color: #757575;
                                            font-size: 10px;
                                          "
                                          ><span
                                            style="text-decoration: none"
                                            >{{
                                              signature.email ||
                                              "example@maverickpwr.com"
                                            }}</span
                                          ></a
                                        ></span
                                      >
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr
                          height="25"
                          style="vertical-align: middle; height: 25px"
                        >
                          <td
                            nowrap
                            width="26"
                            height="25"
                            style="vertical-align: middle; height: 25px"
                          >
                            <table
                              cellpadding="0"
                              cellspacing="0"
                              border="0"
                              globalstyles="[object Object]"
                              style="
                                vertical-align: -webkit-baseline-middle;
                                font-size: medium;
                                font-family: Calibri, sans-serif;
                              "
                            >
                              <tbody>
                                <tr>
                                  <td nowrap style="vertical-align: bottom">
                                    <span
                                      color="#003D8C"
                                      width="15"
                                      style="
                                        display: inline-block;
                                        margin-top: 3px;
                                      "
                                      ><img
                                        src="@/assets/website-icon.png"
                                        color="#003D8C"
                                        alt="website"
                                        width="15"
                                        height="15"
                                        style="
                                          display: block;
                                          width: 15px;
                                          height: 15px;
                                        "
                                    /></span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td
                            nowrap
                            height="25"
                            style="padding: 0px; height: 25px"
                          >
                            <p style="margin: 0">
                              <span
                                style="font-size: 10px; text-decoration: none"
                              >
                                <a
                                  :href="'https://' + signature.company_website"
                                  color="#757575"
                                  target="_blank"
                                  style="
                                    text-decoration: none;
                                    border-bottom: 0px;

                                    color: #757575;
                                    font-size: 10px;
                                  "
                                  ><span style="text-decoration: none">{{
                                    signature.company_website
                                  }}</span></a
                                ></span
                              >
                            </p>
                          </td>
                        </tr>

                        <tr
                          height="25"
                          style="vertical-align: middle; height: 25px"
                        >
                          <td
                            nowrap
                            width="26"
                            height="25"
                            style="vertical-align: middle; height: 25px"
                          >
                            <table
                              cellpadding="0"
                              cellspacing="0"
                              border="0"
                              globalstyles="[object Object]"
                              style="
                                vertical-align: -webkit-baseline-middle;
                                font-size: medium;
                                font-family: Calibri, sans-serif;
                              "
                            >
                              <tbody>
                                <tr>
                                  <td nowrap style="vertical-align: bottom">
                                    <span
                                      color="#003D8C"
                                      width="17"
                                      style="display: block; padding-top: 1px"
                                      ><img
                                        src="@/assets/linkedin-icon.png"
                                        alt="linkedin"
                                        color="#0077B5"
                                        width="17"
                                        height="17"
                                        style="
                                          max-width: 135px;
                                          display: block;
                                          width: 17px;
                                          height: 17px;
                                        "
                                    /></span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td
                            nowrap
                            height="25"
                            style="padding: 0px; height: 25px"
                          >
                            <p style="margin: 0">
                              <span
                                style="font-size: 10px; text-decoration: none"
                              >
                                <a
                                  :href="signature.company_linkedin"
                                  color="#757575"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  data-auth="NotApplicable"
                                  data-linkindex="4"
                                  style="
                                    text-decoration: none;
                                    border-bottom: 0px;
                                    color: #757575;
                                    font-size: 10px;
                                  "
                                >
                                  maverick-power</a
                                >
                              </span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td style="border-bottom: 2px solid #dadada">&nbsp;</td>
        </tr>
        <tr>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td nowrap>
            <img src="@/assets/right-banner-image.png" alt="" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div style="height: 50px"></div>
  <button @click="copyToClipboard" class="btn-clipboard">Copy Signature</button>
</template>

<script>
export default {
  name: "SignatureOutput",
  props: {
    signature: {
      type: Object,
      required: true,
    },
  },
  methods: {
    copyToClipboard() {
      const signatureElement = this.$refs.signaturePreview;

      // Create a temporary container to hold the HTML content of the signature
      const tempElement = document.createElement("div");
      tempElement.appendChild(signatureElement.cloneNode(true));

      // Copy the inner HTML along with the styles
      const htmlContent = tempElement.innerHTML;

      // Use the Clipboard API to write HTML content to the clipboard
      navigator.clipboard
        .write([
          new ClipboardItem({
            "text/html": new Blob([htmlContent], { type: "text/html" }),
          }),
        ])
        .then(() => {
          alert("your signature have been copied to clipboard");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    },
  },
};
</script>
<style>
a:-webkit-any-link {
  text-decoration: none;
  border-bottom: 0px;
}
button {
  width: 100%;
  background: #003d8c;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin: 40px auto 0;
  padding: 14px;
}

button:hover {
  background-color: #002d6c;
}
.btn-clipboard {
  width: 100%;
  background-color: #757575;
  margin: 0;
  padding: 10px 5px;
}
</style>
